import React from 'react';
import Box from '@mui/material/Box';
import Login from './Login';
import { Grid, Typography } from '@mui/material';
import AnimatedPage from '../../layouts/AnimatedPage';

const Auth = () => {
  return (
    <Box data-testid="Terms and condition Page">
      <Grid
        container
        sx={{
          background: `url(${'/images/bg.jpg'}) center center`,
          backgroundSize: 'cover',
        }}
      >
        <Grid
          item
          lg={4}
          md={5}
          sm={6}
          sx={{ opacity: 0.9 }}
          xs={12}
        >
          <AnimatedPage>
            <Login />
          </AnimatedPage>
        </Grid>
        <Grid
          item
          lg={8}
          md={7}
          sm={6}
          xs={0}
        >
          <Typography sx={{ fontSize: 30, fontWeight: 900, pr: 4, pt: 10 }}>
            {' '}
            {/* Manage your sales */}
          </Typography>
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default Auth;

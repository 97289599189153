import React, { useMemo, useState } from 'react';
import { Popover, Box, Typography, Avatar, Grid, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import ChangePassword from '../../page/User/component/ChangePassword';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import Logout from '../../icons/Logout';
import BranchChange from './BranchChange';
import { useGetAllBranchQuery, useGetUserQuery } from '../../page/posApi';
import { IBranchDetails, IUserInfo } from '../../core/Interface/api.interface';
import { useTranslation } from 'react-i18next';

interface ProfilePopupProps {
  profile: HTMLElement | null;
  onClose: () => void;
  open: boolean;
  Username: string | boolean;
  Branchname: string | boolean;
}

const ProfilePopup: React.FC<ProfilePopupProps> = ({ profile, onClose, open, Username, Branchname }) => {
  const id = open ? 'simple-popover' : undefined;
  const [openPassword, setOpenPassword] = React.useState(false); 
  const [openBranchChange, setOpenBranchChange] = useState(false);
  const { t } = useTranslation(['branchManage', 'common']);

  const { data } = useGetUserQuery();
  const branchName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      return userData.branch.name;
    }
    return '';
  }, [data]);

  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  const handleTokenChange = () => {
    window.location.reload();
  };

  const {
    data: branchCountData,
    isLoading,
  } = useGetAllBranchQuery({
    pageIndex: 0,
    pageSize: 50,
    searchText: '',
  });

  const branchList = useMemo(() => {
    return branchCountData?.data as IBranchDetails;
  }, [branchCountData?.data]);

  const showChangeBranchOption = branchList?.records.length > 1;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={profile}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      keepMounted
      PaperProps={{
        style: {
          borderRadius: 16,
        },
      }}
    >
      <Box>
        <Box
          sx={{
            padding: 3,
          }}
        >
          {/* Rest of the content */}
          <Grid container justifyContent="center">
            <Avatar />
          </Grid>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{
              flex: 1, 
            }}
          >
            <strong>{Username}</strong>
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{
              flex: 1, 
            }}
          >
            <strong>{Branchname}</strong>
          </Typography>
        </Box>

        <Divider />
        <Box p={1} />
        <Box>
        {showChangeBranchOption && (
          <ListItemButton onClick={() => setOpenBranchChange(true)}>
            <ListItemIcon>
              <CallSplitIcon />
            </ListItemIcon>
            <ListItemText>
              {t('branchManage:switch')}
            </ListItemText>
          </ListItemButton>
        )}
          <ListItemButton onClick={() => setOpenPassword(true)}>
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText>
              {t('branchManage:changePassword')}
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>
              {t('branchManage:logout')}
            </ListItemText>
          </ListItemButton>
        </Box>
        <Box p={1} />
        {openPassword && (
          <ChangePassword
            handleCloseDialog={() => setOpenPassword(false)}
            openModel={openPassword}
            title={`Change Password`}
          />
        )}
        {openBranchChange && (
          <BranchChange
            handleCloseDialog={() => setOpenBranchChange(false)}
            openModel={openBranchChange}
            title={`Change Branch`}
            onTokenChange={handleTokenChange}
            selectedBranch={branchName}
          />
        )}
        <Divider />
      </Box>
    </Popover>
  );
};

export default ProfilePopup;

// AccessDenied Component
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appColors } from '../../theme/appColors';

const AccessDenied = () => {
  const { t } = useTranslation(['appRoute']);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{ textAlign: 'center', marginTop: '50px', padding: '20px', maxWidth: '400px', margin: 'auto' }}>
        <h1 style={{ fontSize: '2em', marginBottom: '10px' }}>{t('appRoute:accessDenied')}</h1>
        <p style={{ color: appColors.black[80], fontSize: '1em', padding: '20px', }}>
          {t('appRoute:accessDeniedPragraph')}
        </p>
      </div>
    </div>
  );
};

export default AccessDenied;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { IAuth, IBranchDetails } from '../../core/Interface/api.interface';
import { theme } from '../../theme';
import { useBranchSwitchMutation, useGetAllBranchQuery } from '../../page/posApi';
import { appColors } from '../../theme/appColors';
import FieldHeader from '../FieldHeader';
import { useNotifier } from '../../core/Notifier';
import { useAppDispatch } from '../../store/hooks';
import { setEnableAuth } from '../../page/Auth/AuthSlice';


interface IBranchChange {
  openModel?: boolean;
  title: string;
  handleCloseDialog: (close: boolean) => void;
  onTokenChange: () => void;
  selectedBranch: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BranchChange = ({
  title,
  openModel = false,
  handleCloseDialog,
  onTokenChange,
  selectedBranch,
}: IBranchChange) => {
  const [open] = React.useState(openModel);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['branchManage', 'common']);
  const [openBranchSwitch, setOpenBranchSwitch] = useState(false);

  const [branchSwitchData, { isLoading: branchSwitchDataLoading }] = useBranchSwitchMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const dispatch = useAppDispatch();

  const {
    data: branchChangeData,
    isLoading,
  } = useGetAllBranchQuery({
    pageIndex: 0,
    pageSize: 50,
    searchText: '',
  });

  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  
  const handleBranchSwitchData = async (id: string) => {
    try {
      const response = await branchSwitchData(id).unwrap();
      if (response.status) {
        showMessage('Switch Branch successfully');

        const tokenData   = response.data as IAuth
        localStorage.setItem('token', tokenData.token);
        onTokenChange(); 
        // console.log(tokenData.token);
        dispatch(setEnableAuth());

        setOpenBranchSwitch(false);
        handleClose(); 
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      showErrorMessage('Failed to Switch the branch');
    }
  };
  
  const handleSwitchBranch = () => {
    if (selectedBranchId) {
      handleBranchSwitchData(selectedBranchId);
    }
  };

  const branchList = useMemo(() => {
    return branchChangeData?.data as IBranchDetails;
  }, [branchChangeData?.data]);

  const handleClose = () => {
    handleCloseDialog(false);
  };

  useEffect(() => {
    setSelectedBranchId(selectedBranch);
    const initialBranch = branchList?.records.find(branch => branch.name === selectedBranch);
    
    if (initialBranch) {
      setSelectedBranchId(initialBranch.id);
    }
  }, [selectedBranch, branchList?.records]);
  


  return (
    <div>
      <form >
        <Dialog
          aria-describedby="alert-dialog-description"
          classes={{ paper: 'inherit' }}
          fullScreen={isMobile}
          fullWidth={isMobile}
          maxWidth="lg"
          open={open}
        >
          <DialogTitle
            sx={{
              fontWeight: 600,
              p: 3,
              backgroundColor: appColors.blueGreyishLight[30],
              borderRadius: '5px',
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            sx={{ width: isMobile ? 'auto' : '40vw', p: 4, marginTop: '20px' }}
          >
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <FieldHeader title="Branch List" />
                  <FormControl sx={{ width: '100%' }}>
                    <Select
                      MenuProps={MenuProps}
                      value={selectedBranchId || ''}
                      input={<OutlinedInput sx={{ height: `${theme.spacing(5)}` }} />}
                      onChange={(event) => setSelectedBranchId(event.target.value as string)}
                    >
                      {branchList?.records.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {`${branch.name} - ${branch.code}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
            <Button
              onClick={handleClose}
              sx={{
                width: 200,
                height: 42,
                borderRadius: '5px',
                '&:hover': {},
              }}
              variant="outlined"
            >
              {t('common:cancel')}
            </Button>
            <Button
              autoFocus
              disabled={!selectedBranchId || branchSwitchDataLoading}
              onClick={handleSwitchBranch}
              sx={{
                width: 200,
                height: 42,
                borderRadius: '5px',
              }}
              type="submit"
              variant="contained"
            >
              {branchSwitchDataLoading && (
                <Box sx={{ pr: 2, pt: 0.5 }}>
                  <CircularProgress size={16} sx={{ color: 'white' }} />
                </Box>
              )}
              {t('branchManage:switch')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default React.memo(BranchChange);



import React from 'react';
import './i18n/config';
import MainLayout from './page/Main';
import Box from '@mui/material/Box';
import AppRoutes from './layouts/AppRoutes';
import withAppProviders from './withAppProviders';
import LoginProvider from './page/Auth/LoginProvider';
import { BrowserRouter } from 'react-router-dom';
import { BASENAME } from './config';
import { Header } from './layouts/Header';
import { Main, Menu } from './layouts/Menu';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  //  worker.start();
}

const App = () => {
  return (
    <Box data-testid="pos-ui-app">
      <MainLayout>
        <BrowserRouter basename={BASENAME}>
          <LoginProvider>
            <Box>
              <Menu />
              <Header data-testid="Header" />

              <Main open>
                <AppRoutes />
              </Main>
            </Box>
          </LoginProvider>
        </BrowserRouter>
      </MainLayout>
    </Box>
  );
};

export default withAppProviders(App)();
// package json
  // "husky": {
  //   "hooks": {
  //     "pre-push": "cross-env CI=true react-scripts test"
  //   }
  // },
// NotFound Component
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appColors } from '../../theme/appColors';

const NotFound = () => {
  const { t } = useTranslation(['appRoute']);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{ textAlign: 'center', marginTop: '50px', padding: '10px', maxWidth: '400px', margin: 'auto' }}>
        <h1 style={{ fontSize: '2em', marginBottom: '10px' }}>404</h1>
        <h2 style={{ color: appColors.black[100], fontSize: '1.5em' }}>{t('appRoute:pageNotFound')}</h2>
        <p style={{ color: appColors.black[80], fontSize: '1em', padding: '20px', }}>{t('appRoute:Oops')}</p>
      </div>
    </div>
  );
};

export default NotFound;

import React from 'react';
import { styled, useMediaQuery } from '@mui/material';
import useApp from '../../utils/useApp';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentMenuName } = useApp();
  return currentMenuName === '/sales' || currentMenuName === '/purchase'
    ? {}
    : {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: isSmall?0:`${drawerWidth}px`,
        ...(open && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          //   marginLeft: 0,
        }),
      };
});

export default React.memo(Main);

import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

const AppIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          {...props}
        >
            <path
              d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7"
              stroke="#4F4F4F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
        </svg>
    )
}

export default React.memo(AppIcon);

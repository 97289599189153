import React, { useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { header } from '../../styles/header';
import Box from '@mui/material/Box';
import { useGetUserQuery } from '../../page/posApi';
import { IUserInfo } from '../../core/Interface/api.interface';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import { appColors } from '../../theme/appColors';
import ProfilePopup from '../../components/ModelPopup/ProfilePopup';

const Header = ({ ...rest }) => {
  const { data: companyData } = useGetUserQuery();
  const companyName = useMemo(() => {
    if (companyData?.data) {
      const userData = companyData.data as IUserInfo;
      if (userData.company) {
        return userData.company.name;
      }
    }
    return false;
  }, [companyData]);
  const { data } = useGetUserQuery();
  const userName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      return userData.firstName;
    }
    return false;
  }, [data]);

  const branchName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      return userData.branch.name;
    }
    return '';
  }, [data]);

  const [profile, setProfile] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfile(event.currentTarget);
  };

  const handleClose = () => {
    setProfile(null);
  };
  return (
    <Box
      sx={{ ...header }}
      {...rest}
    >
      <AppBar
        elevation={10}
        position="fixed"
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{ pl: 8 }}
          >
            <Grid
              item
              sx={{
                fontSize: '1.4rem',
                pl: 24,
                fontWeight: 800,
                color: appColors.royalBlue[80],
                mt: 0.6,
                
              }}
            >
              {companyName}
            </Grid>
          </Grid>

          <Grid
            container
            data-testid="user-content"
            spacing={3}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: '1rem',
                  color: appColors.white,
                }}
              >
                {userName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: '0.9rem', fontWeight: 600, pt: 0.5 }}>
                {userName}
              </Typography>
              {branchName && (
                <Typography sx={{ fontSize: '0.7rem', fontWeight: 'normal' }}>
                  Branch : {branchName}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Tooltip
                sx={{ fontSize: '14px', fontWeight: 'bold' }}
                title="Open settings"
              >
                <IconButton
                  onClick={handleClick}
                  sx={{
                    borderRadius: '16px',
                    cursor: 'pointer',
                    height: '50px',
                    width: '50px',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: appColors.greyishBlue[40],
                    },
                  }}
                >
                  <Avatar />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <ProfilePopup
            Branchname={branchName}
            Username={userName}
            onClose={handleClose}
            open={Boolean(profile)}
            profile={profile}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
  return (
    <Box
      sx={{ ...header }}
      {...rest}
    >
      <AppBar
        elevation={20}
        position="fixed"
      >
        <Box>
          <Toolbar>
            vdv
            {userName !== '' && (
              <Grid
                container
                data-testid="user-content"
                spacing={3}
              >
                <Grid item>
                  <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>
                    {userName}
                  </Typography>
                  {branchName && (
                    <Typography
                      sx={{ fontSize: '0.7rem', fontWeight: 'normal' }}
                    >
                      Branch : {branchName}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>
                    {userName}
                  </Typography>
                  {branchName && (
                    <Typography
                      sx={{ fontSize: '0.7rem', fontWeight: 'normal' }}
                    >
                      Branch : {branchName}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Tooltip
                    sx={{ fontSize: '14px', fontWeight: 'bold' }}
                    title="Open settings"
                  >
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        // border: '2px solid #004d40',
                        // border: `2px solid ${appColors.greyishBlue[40]}`,
                        borderRadius: '16px',
                        cursor: 'pointer',
                        height: '50px',
                        width: '50px',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: appColors.greyishBlue[40],
                          // border: '1px solid black',
                        },
                      }}
                    >
                      {/* <AccountCircleIcon /> */}
                      <Avatar />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <ProfilePopup
                  Branchname={branchName}
                  Username={userName}
                  onClose={handleClose}
                  open={Boolean(profile)}
                  profile={profile}
                />
              </Grid>
            )}
          </Toolbar>
        </Box>
      </AppBar>
    </Box>
  );
};

export default React.memo(Header);

import { useCallback, useMemo } from 'react';
import { useGetUserQuery } from '../../page/posApi';
import { IUserInfo } from '../Interface/api.interface';

/**
 * usePageQuery hook to set the url params and fetch from the url params
 * @returns
 */
const useUser = () => {
  const { data, isLoading, isError } = useGetUserQuery();
  const userHasRight = useCallback(
    (privilege: string) => {
      if (isLoading || isError) {
        return false;
      }
      const privilegeData = data?.data as IUserInfo;
      return privilegeData.privilages.includes(privilege);
    },
    [data?.data, isError, isLoading]
  );
  const company = useMemo(() => {
    if (isLoading || isError) {
      return {};
    }
    return (data?.data as IUserInfo).company || {};
  }, [data?.data, isError, isLoading]);
  const branch = useMemo(() => {
    if (isLoading || isError) {
      return {};
    }
    return (data?.data as IUserInfo).branch || {};
  }, [data?.data, isError, isLoading]);
  return { userHasRight, company, branch };
};

export default useUser;

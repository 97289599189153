/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import createReducer from './rootReducer';
import { posApi } from '../page/posApi';

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  (module as any).hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

export const initializeStore = {
    reducer: createReducer(),
    middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware<any>) => [
        ...getDefaultMiddleware(),
        posApi.middleware
    ],
}

export const store = configureStore(initializeStore);

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch =
  ThunkDispatch<RootState, void, Action>;


export default store;

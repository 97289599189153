import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../config';
import {
  IApiResponse,
  ICompanyDetailsPayload,
  IBranchDetailsPayload,
  ICustomerDetailsPayload,
  IInventoryDetailsPayload,
  IProductPayload,
  IPurchaseListPayload,
  ISalesDetailsPayload,
  ISupplierDetailsPayload,
  IUser,
  IUserDetailsPayload,
  IReturnSaleDetailsPayload,
  IDashboardPayload,
} from '../core/Interface/api.interface';
import { String } from 'lodash';

/**
 * Holds all the API callbacks
 * @returns RTK Implementation for backend
 */

export const posApi = createApi({
  reducerPath: 'posApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      headers.set(
        'Authorization',
        `Bearer ${localStorage.getItem('token') || ''}`
      );
      return headers;
    },
  }),
  tagTypes: [
    'User',
    'Category',
    'Product',
    'Dashboard',
    'Sales',
    'Purchase',
    'Inventory',
    'BillPayments',
    'BillPaymentsHistory',
    'UserManage',
    'ReturnSales',
    'BillPaymentsUserInfo',
    'SupplierManage',
    'Company',
    'BranchManage',
    'Customer',
    'ReturnSale',
    'ReturnSaleList',
    'branchSwitch',
    'getPatientById',
    'getPatient',
    'getCustomerByMobile',
    'getSupplierByMobile'
  ],
  endpoints: (builder) => ({
    getUser: builder.query<IApiResponse, void>({
      query: (request) => {
        return {
          url: '/user/userInfo',
          method: 'GET',
          body: request,
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ['User'],
    }),
    login: builder.mutation<IApiResponse, IUser>({
      query: (request) => {
        return {
          url: '/login/authenticate',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
    }),
    getDashboard: builder.query<IApiResponse, IDashboardPayload>({
      query: () => {
        return {
          url: '/dashboard/info',
          method: 'POST',
          body: JSON.stringify({}),
        };
      },
      providesTags: ['Dashboard'],
      keepUnusedDataFor: 0,
    }),
    getCategory: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: '/category/getAll',
          method: 'GET',
        };
      },
      providesTags: ['Category'],
      keepUnusedDataFor: 0,
    }),
    saveCategory: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/category/save',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Category'],
    }),
    getAllProduct: builder.query<IApiResponse, IProductPayload>({
      query: (request) => {
        return {
          url: '/product/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['Product'],
      keepUnusedDataFor: 0,
    }),
    saveProduct: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/product/save',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Product'],
    }),
    getAllSalesDetails: builder.query<IApiResponse, ISalesDetailsPayload>({
      query: (request) => {
        return {
          url: '/sales/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['Sales'],
      keepUnusedDataFor: 0,
    }),
    saveSales: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/sales/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Dashboard', 'Sales'],
    }),
    getSalesDetailsByOrderId: builder.query<IApiResponse, string>({
      query: (orderId) => {
        return {
          url: `/sales/getReceiptDataById?Id=${orderId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
    deleteCategory: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/category/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Category'],
    }),
    getAllPurchaseList: builder.query<IApiResponse, IPurchaseListPayload>({
      query: (request) => {
        return {
          url: '/purchase/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['Purchase'],
      keepUnusedDataFor: 0,
    }),
    deleteProduct: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/product/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Product'],
    }),
    savePurchase: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/purchase/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Purchase'],
    }),
    getAllInventory: builder.query<IApiResponse, IInventoryDetailsPayload>({
      query: (request) => {
        return {
          url: '/Inventory/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['Inventory'],
      keepUnusedDataFor: 0,
    }),
    getPurchaseDetailsByOrderId: builder.query<IApiResponse, string>({
      query: (orderId) => {
        return {
          url: `/Purchase/getInvoiceDataById?Id=${orderId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
    getAllServices: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: '/BillPayments/getAllServices',
          method: 'GET',
        };
      },
      providesTags: ['BillPayments'],
      // keepUnusedDataFor: 0,
    }),
    getAllBillHistory: builder.query<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/BillPayments/getHistoryAll',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      providesTags: ['BillPaymentsHistory'],
      keepUnusedDataFor: 0,
    }),
    payBillPayment: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/BillPayments/payBill',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['BillPaymentsHistory', 'BillPaymentsUserInfo'],
    }),
    changePassword: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/user/resetPassword',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
    }),
    newUser: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/user/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['UserManage'],
    }),
    getAllUserPrivileges: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: '/user/getAllUserPrivileges',
          method: 'GET',
        };
      },
    }),
    getAllUserManage: builder.query<IApiResponse, IUserDetailsPayload>({
      query: (request) => {
        return {
          url: '/user/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['UserManage'],
      keepUnusedDataFor: 0,
    }),
    deleteUserManage: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/user/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserManage'],
    }),
    getBillPaymentUserInfo: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: '/BillPayments/getUserInfo',
          method: 'POST',
          body: JSON.stringify({}),
        };
      },
      providesTags: ['BillPaymentsUserInfo'],
      keepUnusedDataFor: 0,
    }),
    newSupplier: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/supplier/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['SupplierManage'],
    }),
    getAllSupplierManage: builder.query<IApiResponse, ISupplierDetailsPayload>({
      query: (request) => {
        return {
          url: '/supplier/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['SupplierManage'],
      keepUnusedDataFor: 0,
    }),
    deleteSupplierManage: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/supplier/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['SupplierManage'],
    }),
    newCompany: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/Company/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Company'],
    }),
    getAllCompany: builder.query<IApiResponse, ICompanyDetailsPayload>({
      query: (request) => {
        return {
          url: '/Company/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['Company'],
      keepUnusedDataFor: 0,
    }),
    deleteCompany: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/Company/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Company'],
    }),
    newBranch: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/Branch/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['BranchManage'],
    }),
    deleteBranchManage: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/Branch/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['BranchManage'],
    }),
    getAllBranch: builder.query<IApiResponse, IBranchDetailsPayload>({
      query: (request) => {
        return {
          url: '/Branch/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['BranchManage'],
      keepUnusedDataFor: 0,
    }),
    newCustomer: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/Customer/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Customer'],
    }),
    getAllCustomer: builder.query<IApiResponse, ICustomerDetailsPayload>({
      query: (request) => {
        return {
          url: '/Customer/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['Customer'],
      keepUnusedDataFor: 0,
    }),
    deleteCustomer: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `/Customer/delete?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Customer'],
    }),
    getReturnSalesOrderById: builder.query<IApiResponse, string>({
      query: (orderId) => {
        return {
          url: `/Sales/getByOrderId?orderId=${orderId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['ReturnSales'],
    }),
    newSalesReturn: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/SalesReturn/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['ReturnSales'],
    }),
    getAllReturn: builder.query<IApiResponse, IReturnSaleDetailsPayload>({
      query: (request) => {
        return {
          url: '/SalesReturn/getAll',
          method: 'POST',
          body: request,
        };
      },
      providesTags: ['ReturnSaleList'],
      keepUnusedDataFor: 0,
    }),
    branchSwitch: builder.mutation<IApiResponse, string>({
      query: (Id) => {
        return {
          url: `Login/branchSwitch?Id=${Id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['branchSwitch'],
    }),

    savePatient: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/patient/register',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['getPatient'],
    }),

    getPatientById: builder.query<IApiResponse, string>({
      query: (id) => {
        return {
          url: `/Patient/getByPatientNumber?PatientNumber=${id}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['getPatientById'],
    }),
    registerTreatment: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/patient/registerTreatment',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['getPatientById'],
    }),

    getAllPatients: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: `/Patient/getAll`,
          method: 'POST',
          body: JSON.stringify({
            pageIndex: 0,
            pageSize: 10000,
            searchText: '',
          }),
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['getPatient'],
    }),

    deletePatient: builder.mutation<IApiResponse, string>({
      query: (id) => {
        return {
          url: '/patient/delete?id=' + id,
          method: 'GET',
        };
      },
      invalidatesTags: ['getPatient'],
    }),
    deleteTreatments: builder.mutation<IApiResponse, string>({
      query: (id) => {
        return {
          url: '/patient/deleteTreatment?id=' + id,
          method: 'GET',
        };
      },
      invalidatesTags: ['getPatientById'],
    }),
    getCustomerByMobile: builder.query<IApiResponse, string>({
      query: (mobileNumber) => {
        return {
          url: `Customer/getByMobileNumber?mobileSearchText=${mobileNumber}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['getCustomerByMobile'],
    }),
    CustomerpayCredit: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: `/Customer/payCredit`,
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ['Customer'],
    }),
    getAllCreditHistory: builder.query<IApiResponse, Object>({
      query: (request) => {
        return {
          url: '/Customer/getAllCreditHistory',
          method: 'POST',
          body: JSON.stringify(request),
        };
      },
      providesTags: ['Customer'],
      keepUnusedDataFor: 0,
    }),

    // getPatientById: builder.query<IApiResponse, string>({
    //   query: (id) => {
    //     return {
    //       url: `/Patient/getByPatientNumber?PatientNumber=${id}`,
    //       method: 'GET',
    //     };
    //   },
    //   keepUnusedDataFor: 0,
    //   providesTags: ['getPatientById'],
    // }),
    getSupplierByMobile: builder.query<IApiResponse, string>({
      query: (mobileNumber) => {
        return {
          url: `Supplier/getByMobileNumber?mobileSearchText=${mobileNumber}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['getSupplierByMobile'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useGetDashboardQuery,
  useGetCategoryQuery,
  useSaveCategoryMutation,
  useGetAllProductQuery,
  useSaveProductMutation,
  useGetAllSalesDetailsQuery,
  useSaveSalesMutation,
  useGetSalesDetailsByOrderIdQuery,
  useDeleteCategoryMutation,
  useGetAllPurchaseListQuery,
  useDeleteProductMutation,
  useSavePurchaseMutation,
  useGetAllInventoryQuery,
  useGetPurchaseDetailsByOrderIdQuery,
  useGetAllServicesQuery,
  useGetAllBillHistoryQuery,
  usePayBillPaymentMutation,
  useChangePasswordMutation,
  useNewUserMutation,
  useGetAllUserPrivilegesQuery,
  useGetAllUserManageQuery,
  useDeleteUserManageMutation,
  useGetBillPaymentUserInfoQuery,
  useNewBranchMutation,
  useDeleteBranchManageMutation,
  useNewSupplierMutation,
  useGetAllSupplierManageQuery,
  useDeleteSupplierManageMutation,
  useNewCompanyMutation,
  useGetAllCompanyQuery,
  useDeleteCompanyMutation,
  useGetAllBranchQuery,
  useGetAllCustomerQuery,
  useNewCustomerMutation,
  useDeleteCustomerMutation,
  useGetReturnSalesOrderByIdQuery,
  useNewSalesReturnMutation,
  useGetAllReturnQuery,
  useBranchSwitchMutation,
  useSavePatientMutation,
  useGetPatientByIdQuery,
  useRegisterTreatmentMutation,
  useGetAllPatientsQuery,
  useDeletePatientMutation,
  useDeleteTreatmentsMutation,
  useGetCustomerByMobileQuery,
  useCustomerpayCreditMutation,
  useGetAllCreditHistoryQuery,
  useGetSupplierByMobileQuery,
} = posApi;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IAddQty,
  IInventoryBasketStates,
} from '../../../core/Interface/property.interface';

export interface BasketItem extends IAddQty {}

export interface BasketState {
  items: BasketItem[];
  mobile?: string;
  receiptId?: string;
}

const initialState: BasketState = {
  items: [],
};

export const basket = createSlice({
  name: 'purchaseBasket',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<BasketItem>) => {
      const newItem = { ...action.payload };
      const existingItem = state.items.findIndex(
        (element) => element.id === newItem.id
      );

      if (existingItem !== -1) {
        state.items[existingItem].quanity = newItem.quanity;
        state.items[existingItem].sellingPrice = newItem.sellingPrice;
        state.items[existingItem].cost = newItem.cost;
      } else {
        state.items.push(newItem);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      const findItemIndex = state.items.findIndex(
        (element) => element.id === action.payload
      );
      if (findItemIndex > -1) {
        state = { ...state, items: state.items.splice(findItemIndex, 1) };
      }
    },
    removeBasket: (state) => {
      state = { ...initialState };
      return state;
    },

    addMobileReceiptID: (
      state,
      action: PayloadAction<IInventoryBasketStates>
    ) => {
      state.mobile = action.payload.mobile;
      state.receiptId = action.payload.receiptId;
    },
  },
});

export const { addItem, removeItem, removeBasket, addMobileReceiptID } =
  basket.actions;

export const PurchaseBasketReducer = basket.reducer;

export { initialState as BasketInitialState };
